import { BaseLayout } from "./../../../common/components/layouts/BaseLayout";

export function RentLayout({ children }) {
  return (
    <BaseLayout>
      <div className="container">
        <div className="row">
          <div className="col-24 col-md-22 ms-auto me-auto">{children}</div>
        </div>
      </div>
    </BaseLayout>
  );
}

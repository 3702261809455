import mergeProps from "merge-props";

import { styled } from "stitches.config";
import Link from "next/link";
import { useRouter } from "next/router";

const ProgressBarContainerStyled = styled("div", {});

const ProgressStepsWrapperStyled = styled("div", {
  display: "flex",
  justifyContent: "space-between",
});

const ProgressStepWrapperStyled = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flex: "1",
  width: "16.666%",
});

const ProgressStepStyled = styled("a", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "32px",
  height: "32px",
  marginBottom: "0.25rem",
  border: "1px solid #e3e6e8",
  borderRadius: "50%",
  background: "#fff",

  variants: {
    active: {
      true: {
        borderColor: "#45c0c3",
        background: "#45c0c3",
        cursor: "pointer",
      },
    },
    disabled: {
      true: {
        pointerEvents: "none",
      },
    },
  },
});

const ProgressStepNumberStyled = styled("div", {
  fontSize: "0.75rem",
  fontWeight: "500",
  color: "#ADB9C1",

  variants: {
    active: {
      true: {
        color: "#fff",
      },
    },
  },
});

const ProgressStepTextStyled = styled("div", {
  padding: "4px 2px",
  fontSize: "0.6125rem",
  fontWeight: "500",
  lineHeight: "1.25",
  color: "#ADB9C1",
  textAlign: "center",
  WebkitHyphens: "auto",
  hyphens: "auto",

  "@lg": {
    fontSize: "0.75rem",
  },

  variants: {
    active: {
      true: {
        color: "#6D7D8B",
      },
    },
  },
});

const ProgressBarStyled = styled("div", {
  position: "absolute",
  top: "16px",
  left: "50%",
  zIndex: "-1",
  width: "calc(100% - 16.666%)",
  height: "1px",
  transform: "translateX(-50%)",
  background: "#E3E6E8",

  "@md": {
    top: "16px",
  },
});

const ProgressBarFilledStyled = styled("div", {
  width: "100%",
  height: "1px",
  background: "#45C0C3",
});

const steps = [
  {
    number: "1",
    text: "Wyszukaj produkt",
    link: "/rent",
  },
  {
    number: "2",
    text: "Zweryfikuj cenę",
    link: "/rent/[orderCode]/price",
  },
  {
    number: "3",
    text: "Wybierz okres",
    link: "/rent/[orderCode]/period",
  },
  {
    number: "4",
    text: "Uzupełnij dane",
    link: "/rent/[orderCode]/personal-data",
  },
  {
    number: "5",
    text: "Weryfikacja tożsamości",
    link: "/rent/[orderCode]/personal-data-details",
  },
  {
    number: "6",
    text: "Podsumowanie",
    link: "/rent/[orderCode]/summary",
  },
];

export function ProgressBar(props) {
  const { activeStep } = props;
  const innerProps = mergeProps(props, {
    className: "rce",
  });

  const router = useRouter();
  const orderCode = router.query?.code;

  delete innerProps.title;
  delete innerProps.activeStep;

  const progressPercentage = `${(100 / (steps.length - 1)) * (activeStep - 1)}%`;

  return (
    <ProgressBarContainerStyled {...innerProps}>
      <div className={"position-relative me-lg-n4"}>
        <ProgressBarStyled>
          <ProgressBarFilledStyled css={{ width: progressPercentage }} />
        </ProgressBarStyled>
        <ProgressStepsWrapperStyled>
          {steps &&
            steps.map((step) => {
              const currentStep = steps.indexOf(step) + 1;
              const isDoneStep = currentStep < activeStep;
              const isActiveStep = currentStep === activeStep;
              const isLinkRequireOrderCode = step.link?.includes("orderCode");
              return (
                <ProgressStepWrapperStyled key={step.number}>
                  <Link
                    href={{
                      pathname: step.link,
                      query: isLinkRequireOrderCode ? { orderCode: orderCode } : {},
                    }}
                  >
                    <ProgressStepStyled active={isDoneStep || isActiveStep} disabled={!isDoneStep}>
                      <ProgressStepNumberStyled active={isDoneStep || isActiveStep}>
                        {step.number}
                      </ProgressStepNumberStyled>
                    </ProgressStepStyled>
                  </Link>
                  <ProgressStepTextStyled active={isDoneStep || isActiveStep}>{step.text}</ProgressStepTextStyled>
                </ProgressStepWrapperStyled>
              );
            })}
        </ProgressStepsWrapperStyled>
      </div>
    </ProgressBarContainerStyled>
  );
}

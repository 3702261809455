import cookie from "cookie";
import { useState } from "react";
import { useRouter } from "next/router";
import { styled } from "stitches.config";
import { Portal } from "src/common/components/functional/Portal";
import { VerificationError } from "src/ecommerce/components/rent/Verification/VerificationError";
import { FindProduct } from "src/ecommerce/components/rent/FindProduct";
import { sendGTMEvent } from "@next/third-parties/google";

import { create } from "src/ecommerce/api/order";

import SmileIcon from "../../../assets/svg/smile.svg";
import LoaderIcon from "public/svg/icons/loader.svg";

export function RentFindProduct() {
  const router = useRouter();

  const [product, setProduct] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const addToOrder = async (productData) => {
    const data = {
      status: 1, // Draft
      productPrice: productData?.price,
      productName: productData?.name,
      productCategoryName: productData?.category?.name,
      mediaExpertProductId: productData?.mediaExpertProductId,
      tags: productData?.tags?.data?.map((tag) => tag?.id) || [],
    };

    populateCoupon(data);

    setLoadingCreate(true);

    const order = await create({
      data: data,
    });

    setApiResponse(order);

    if (order && order?.data?.attributes?.code) {
      const orderCode = order?.data?.attributes?.code;
      sendGTMEvent({ event: "form_checkout_find_product_submit", product: product });
      router.push(`/rent/${orderCode}/price`);
    }

    setLoadingCreate(false);
  };

  const productName = product?.name;

  return (
    <>
      <FindProduct onChange={setProduct} onError={setApiResponse} />
      <Portal id="form-errors">
        <VerificationError className="mb-6" validationErrors={apiResponse?.error} data-test-find-error />
      </Portal>
      {product && (
        <div className="rce border rounded-3 p-6" data-test-product>
          <div className="row align-items-center">
            <div className="col-8 col-md-4 px-lg-0">
              <ProductImageContainer className="d-flex justify-content-center">
                <SmileIcon width="48px" height="100%" />
              </ProductImageContainer>
            </div>

            <div className="col-16 col-md mb-lg-0 mb-4 mt-lg-0 mt-3  ps-0" data-test-product-header>
              <p className="fs-m fw-semibold m-0">{productName}</p>
            </div>
            <div className="col-24 col-md-auto mt-lg-0 mt-5">
              <button
                className="btn btn-lg btn-primary fw-semibold text-white px-6 ms-auto"
                onClick={() => {
                  addToOrder(product);
                }}
                disabled={loadingCreate}
                data-test-product-rent-button
              >
                Wynajmij
                {loadingCreate && <LoaderIcon className={"ms-3 me-n3"} width={24} height={24} fill="#fff" />}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function populateCoupon(data) {
  const cookies = cookie.parse(document.cookie);

  if (cookies._gl_coupon && cookies._gl_coupon.length > 0) {
    data.coupon = cookies._gl_coupon;
  }
}

const ProductImageContainer = styled("div", {
  span: {
    maxHeight: "80px",
  },
});

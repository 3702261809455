import mergeProps from "merge-props";

import { styled } from "stitches.config";

const UNKNOWN_VERIFICATION_ERROR = "UnknownError";

const VerificationErrorStyled = styled("div", {
  border: "1px solid #dee2e6",
  borderRadius: "1.25rem",
  overflow: "hidden",
  borderColor: "#842029",
  color: "#842029",
  backgroundColor: "#fcebec",
});

export const VerificationError = (props) => {
  const { verification, validationErrors, ...componentProps } = props;
  const defaultProps = { className: "px-6 py-5 rce" };
  const mergedProps = mergeProps(defaultProps, componentProps);

  const priceLimitError = verification?.errors?.find((error) => {
    const errorName = error?.name;

    if (
      ["ValidationErrorMaxProductPriceLimitExceeded", "ValidationErrorMinProductPriceLimitExceeded"].includes(errorName)
    ) {
      return true;
    }
    return false;
  });

  const productConfigNotFoundError = verification?.errors?.find((error) => {
    const errorName = error?.name;

    if (["ValidationNoEligiblePricingFound", "ValidationErrorCategoryNotFound"].includes(errorName)) {
      return true;
    }
    return false;
  });

  const unknowError = verification?.errors?.find((error) => {
    return error.name === UNKNOWN_VERIFICATION_ERROR;
  });

  if (priceLimitError) {
    return (
      <VerificationErrorStyled {...mergedProps}>
        <p>Niestety, wybrany produkt nie spełnia warunków wynajmu</p>
      </VerificationErrorStyled>
    );
  }

  if (productConfigNotFoundError) {
    return (
      <VerificationErrorStyled {...mergedProps}>
        <p>Niestety, wybrany produkt jest niedostępny</p>
      </VerificationErrorStyled>
    );
  }

  if (unknowError) {
    return (
      <VerificationErrorStyled {...mergedProps}>
        <p>Przepraszamy ale nie możemy teraz przeprowadzić procesu weryfikacji. Proszę spróbuj później.</p>
      </VerificationErrorStyled>
    );
  }

  return "";
};
